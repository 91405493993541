<template><div><!--
NOTE - do not edit the supported-devices.md file, edit the template file in the supported-devices folder
-->
<h1 id="supported-devices" tabindex="-1"><a class="header-anchor" href="#supported-devices"><span>Supported Devices</span></a></h1>
<h2 id="determining-device-support" tabindex="-1"><a class="header-anchor" href="#determining-device-support"><span>Determining Device Support</span></a></h2>
<p>What's in a name? All ChromeOS devices have a board/device name, which determines which firmware, OS build, etc a device uses. The board name is listed at the bottom of both the Recovery Mode and Developer Mode screens, as part of the hardware ID (HWID). <strong>Your device's board name is what determines if it's supported or not</strong> -- not the make, model, CPU, or anything else. The only thing that matters is the name, so use that when determining support in the table below (or for anything else really).</p>
<p>The chart below provides a full, authoritative list of all ChromeOS devices currently supported by the Firmware Utility Script, the firmware types available for each, and the hardware write-protect method used. In most cases, all of the devices in a given platform/family are supported identically, but sometimes there are outliers (esp with older or newer devices). The goal is to offer both RW_LEGACY and UEFI Full ROM firmware for all devices wherever possible, so you have the option of dual booting ChromeOS, or liberating your device completely 😃</p>
<h2 id="os-support" tabindex="-1"><a class="header-anchor" href="#os-support"><span>OS Support</span></a></h2>
<p>A device having firmware available (either RW_LEGACY or UEFI Full ROM) does not imply any level of functionality when running an OS other than ChromeOS. Some devices/platforms are better supported in some Linux distros vs others. Some devices/platforms are better supported under Windows than others.</p>
<p>The best resource for OS compatibility is the <a href="https://docs.chrultrabook.com/docs/firmware/supported-devices.html" target="_blank" rel="noopener noreferrer">chrultrabook Supported Devices Page</a></p>
<h2 id="device-listing" tabindex="-1"><a class="header-anchor" href="#device-listing"><span>Device Listing</span></a></h2>
<p>Supported devices are listed in the table below, grouped by platform/family, roughly in chronological order. To reiterate, support is determined entirely and exclusively by the device's ChromeOS HWID/board name. Device names are just for reference, and may be incomplete as many models/sub-models may use the same HWID/board name. Please don't ask if your HP Chromebook 14b-ca0023dx (eg) is supported. Figure out the Board Name and then locate it in the table. If it's not listed, it's not supported (in which case you are encouraged to email me so I can add support for it).</p>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>If you're on a smaller screen, scroll sideways to see whole table.</p>
</div>
<AddScript script-url="../../supported-devices.js"/><p>Search: <input type="text" class="deviceSearch"></p>
<div class="deviceTable">
<table style="font-size: 14px !important;">
    <tbody>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Sandybridge/Ivybridge</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>HP Pavilion Chromebook 14</td>
            <td style="text-align:center;"> BUTTERFLY</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Butterfly_wp_switch.jpg" class="internal" title="Butterfly_wp_switch.jpg" target="_blank">switch</a></td>
        </tr>
        <tr>
            <td>Google Chromebook Pixel (2013)</td>
            <td style="text-align:center;"> LINK</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Link_wp_screw.jpg" class="internal" title="Link_wp_screw.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Samsung Chromebook Series 5 550</td>
            <td style="text-align:center;"> LUMPY</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Lumpy_wp_jumper.jpg" class="internal" title="Lumpy_wp_jumper.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer C7/C710 Chromebook</td>
            <td style="text-align:center;"> PARROT</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Parrot_wp_jumper.jpg" class="internal" title="Parrot_wp_jumper.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Lenovo Thinkpad X131e Chromebook</td>
            <td style="text-align:center;"> STOUT</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/stout_wp.jpg" class="internal" title="stout wp.jpg" target="_blank">switch</a></td>
        </tr>
        <tr>
            <td>Samsung Chromebox Series 3</td>
            <td style="text-align:center;"> STUMPY</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Haswell</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>HP Chromebook 14</td>
            <td style="text-align:center;"> FALCO</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://www.youtube.com/watch?v=_8Gb0YjQCsw" class="external" title="Falco WP screw video" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Toshiba Chromebook 13 (CB30)</td>
            <td style="text-align:center;"> LEON</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Leon_wp_screw.jpg" class="internal" title="Leon_wp_screw.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Acer Chromebox CXI</td>
            <td style="text-align:center;"> MCCLOUD</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/mccloud_wp.jpg" class="internal" title="mccloud_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>LG Chromebase 22</td>
            <td style="text-align:center;"> MONROE</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Asus Chromebox CN60</td>
            <td style="text-align:center;"> PANTHER</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/panther_wp.jpg" class="internal" title="panther_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Acer C720/C720P Chromebook</td>
            <td style="text-align:center;"> PEPPY</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Peppy_wp_screw.jpg" class="internal" title="Peppy_wp_screw.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Dell Chromebox 3010</td>
            <td style="text-align:center;"> TRICKY</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/panther_wp.jpg" class="internal" title="panther_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 11 (CB1C13)</td>
            <td style="text-align:center;"> WOLF</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Wolf_wp_screw.jpg" class="internal" title="Wolf_wp_screw.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>HP Chromebox CB1 / G1</td>
            <td style="text-align:center;"> ZAKO</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/panther_wp.jpg" class="internal" title="panther_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Broadwell</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Acer C740 Chromebook</td>
            <td style="text-align:center;"> AURON_PAINE / PAINE</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Acer C910 Chromebook (CB5-571)</td>
            <td style="text-align:center;"> AURON_YUNA / YUNA</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Acer Chromebase 24</td>
            <td style="text-align:center;"> BUDDY</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Buddy_wp_screw.jpg" class="internal" title="Buddy_wp_screw.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Toshiba Chromebook 2 (2015)</td>
            <td style="text-align:center;"> GANDOF</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Asus Chromebox 2 (CN62)</td>
            <td style="text-align:center;"> GUADO</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/panther_wp.jpg" class="internal" title="panther_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 13 7310</td>
            <td style="text-align:center;"> LULU</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Acer Chromebox CXI2</td>
            <td style="text-align:center;"> RIKKU</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/mccloud_wp.jpg" class="internal" title="mccloud_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Google Chromebook Pixel (2015)</td>
            <td style="text-align:center;"> SAMUS</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Samus_wp_screw.jpg" class="internal" title="Samus_wp_screw.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Lenovo ThinkCentre Chromebox</td>
            <td style="text-align:center;"> TIDUS</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/tidus_wp.jpg" class="internal" title="tidus_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Baytrail</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Acer Chromebook 15 (CB3-531)</td>
            <td style="text-align:center;"> BANJO</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Dell Chromebook 11 (3120)</td>
            <td style="text-align:center;"> CANDY</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Lenovo N20/N20P Chromebook</td>
            <td style="text-align:center;"> CLAPPER</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>CTL N6 Education Chromebook<br>Lenovo N21 Chromebook</td>
            <td style="text-align:center;"> ENGUARDE</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Lenovo ThinkPad 11e/Yoga Chromebook</td>
            <td style="text-align:center;"> GLIMMER</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Acer Chromebook 11 (CB3-111/131, C730, C730E, C735)</td>
            <td style="text-align:center;"> GNAWTY</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/gnawty_wp.jpg" class="internal" title="gnawty_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Haier Chromebook G2</td>
            <td style="text-align:center;"> HELI</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>HP Chromebook 11 G3/G4<br>HP Chromebook 14 G4</td>
            <td style="text-align:center;"> KIP</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>AOpen Chromebox Commercial</td>
            <td style="text-align:center;"> NINJA</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Lenovo Ideapad 100S Chromebook</td>
            <td style="text-align:center;"> ORCO</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Asus Chromebook C300</td>
            <td style="text-align:center;"> QUAWKS</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Asus Chromebook C200</td>
            <td style="text-align:center;"> SQUAWKS</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>AOpen Chromebase Commercial</td>
            <td style="text-align:center;"> SUMO</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Toshiba Chromebook 2 (2014)</td>
            <td style="text-align:center;"> SWANKY</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Samsung Chromebook 2 (XE500C12)</td>
            <td style="text-align:center;"> WINKY</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Braswell</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Acer Chromebook 15 (CB3-532)</td>
            <td style="text-align:center;"> BANON</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Banon_wp.jpg" class="internal" title="Banon wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Samsung Chromebook 3</td>
            <td style="text-align:center;"> CELES</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Acer Chromebook R11 (C738T, CB5-132T)</td>
            <td style="text-align:center;"> CYAN</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/cyan_wp.jpg" class="internal" title="cyan_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 14 (CB3-431)</td>
            <td style="text-align:center;"> EDGAR</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Edgar_wp.jpg" class="internal" title="Edgar wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 11 3180/3189</td>
            <td style="text-align:center;"> KEFKA</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Lenovo N22/N23/N42 Chromebook</td>
            <td style="text-align:center;"> REKS</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Acer Chromebook 11 N7 (C731)<br>CTL NL61 Chromebook<br>EduGear Chromebook R<br>Edxis Education Chromebook (NL6D)<br>HP Chromebook 11 G5 EE<br>Mecer V2 Chromebook<br>Positivo Chromebook C216B</td>
            <td style="text-align:center;"> RELM</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/C731_wp.jpg" class="internal" title="C731 wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 11 G5</td>
            <td style="text-align:center;"> SETZER</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/setzer_wp.png" class="internal" title="setzer_wp.png" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook C202S/C202SA<br>Asus Chromebook C300SA/C301SA</td>
            <td style="text-align:center;"> TERRA</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/C202sa_wp.jpg" class="internal" title="C202sa wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Lenovo ThinkPad 11e/Yoga Chromebook (G3)</td>
            <td style="text-align:center;"> ULTIMA</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>CTL J5 Chromebook<br>Edugear CMT Chromebook<br>Haier Chromebook 11 C<br>Multilaser Chromebook M11C<br>PCMerge Chromebook PCM-116T-432B<br>Prowise Chromebook Proline<br>Viglen Chromebook 360</td>
            <td style="text-align:center;"> WIZPIG</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Skylake</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Dell Chromebook 13 3380</td>
            <td style="text-align:center;"> ASUKA</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Samsung Chromebook Pro</td>
            <td style="text-align:center;"> CAROLINE</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="/images/wp/Caroline_wp.jpg" class="internal" title="Caroline_wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook C302CA</td>
            <td style="text-align:center;"> CAVE</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>HP Chromebook 13 G1</td>
            <td style="text-align:center;"> CHELL</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Acer Chromebook 14 for Work<br>Acer Chromebook 11 (C771/C771T)</td>
            <td style="text-align:center;"> LARS</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td>Lenovo Thinkpad 13 Chromebook</td>
            <td style="text-align:center;"> SENTRY</td>
            <td style="text-align:center;"> <span style="color:#ff0000"><b>EOL</b></span></td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> screw</td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Apollolake</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Acer Chromebook 11 (C732)</td>
            <td style="text-align:center;"> ASTRONAUT</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook C223NA<br>ASUS Chromebook CX1100CNA</td>
            <td style="text-align:center;"> BABYMEGA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook C523NA<br>ASUS Chromebook CX1500CNA</td>
            <td style="text-align:center;"> BABYTIGER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebook NL7<br>Edxis Chromebook 11<br>Lanix Chromebook C116<br>Multilaser Chromebook M11C-PC912<br>Positivo Chromebook N2110</td>
            <td style="text-align:center;"> BLACKTIP</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebook NL7T/NL7TW<br>Edxis Chromebook X11<br>Multilaser Chromebook M11HC-PC911<br>Positivo Chromebook N2112<br>Viglen Chromebook 360C</td>
            <td style="text-align:center;"> BLACKTIP360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebook NL7 LTE</td>
            <td style="text-align:center;"> BLACKTIPLTE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 15 (CB315)</td>
            <td style="text-align:center;"> BLUE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 15 (CP315)</td>
            <td style="text-align:center;"> BRUCE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 514 (CB514-1H, CB514-1HT)</td>
            <td style="text-align:center;"> EPAULETTE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 11 (CP311-1H, CP311-1HN)</td>
            <td style="text-align:center;"> LAVA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 11 5190</td>
            <td style="text-align:center;"> NASHER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 11 5190 2-in-1</td>
            <td style="text-align:center;"> NASHER360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo Thinkpad 11e/Yoga 11e (G4)</td>
            <td style="text-align:center;"> PYRO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook C423<br>ASUS Chromebook CX1400CNA</td>
            <td style="text-align:center;"> RABBID</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip C213SA<br>Acer Chromebook Spin 11 (R751T)</td>
            <td style="text-align:center;"> REEF</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 100e Chromebook</td>
            <td style="text-align:center;"> ROBO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 500e Chromebook</td>
            <td style="text-align:center;"> ROBO360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 15 (CB515-1H, CB515-1HT)</td>
            <td style="text-align:center;"> SAND</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 11 (CB311-8H, CB311-8HT)</td>
            <td style="text-align:center;"> SANTA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 11 G1 EE<br>HP Chromebook 11 G6<br>HP Chromebook 14 G5</td>
            <td style="text-align:center;"> SNAPPY</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebook J41/J41T<br>PCmerge Chromebook AL116<br>Prowise Chromebook Eduline<br>Sector 5 E3 Chromebook<br>Viglen Chromebook 11C</td>
            <td style="text-align:center;"> WHITETIP</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Kabylake / Amberlake</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Acer Chromebook 13 (CB713-1W)</td>
            <td style="text-align:center;"> AKALI</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 13 (CP713-1WN)</td>
            <td style="text-align:center;"> AKALI360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Google Pixelbook Go (2019)</td>
            <td style="text-align:center;"> ATLAS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 715 (CB715)</td>
            <td style="text-align:center;"> BARD</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 714 (CB714)</td>
            <td style="text-align:center;"> EKKO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Meet Compute System - Series One (Lenovo)</td>
            <td style="text-align:center;"> ENDEAVOUR</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Excelsior_wp.jpg" class="internal" title="Excelsior wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Google Pixelbook (2017)</td>
            <td style="text-align:center;"> EVE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Google Meet kit (KBL)</td>
            <td style="text-align:center;"> EXCELSIOR</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Excelsior_wp.jpg" class="internal" title="Excelsior wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>AOpen Chromebox Commercial 2<br>Newline Chromebox A10</td>
            <td style="text-align:center;"> JAX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/fizz_wp.jpg" class="internal" title="fizz wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Acer Chromebase 24I2</td>
            <td style="text-align:center;"> KARMA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, screw</td>
        </tr>
        <tr>
            <td>HP Chromebox G2</td>
            <td style="text-align:center;"> KENCH</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/fizz_wp.jpg" class="internal" title="fizz wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook C425</td>
            <td style="text-align:center;"> LEONA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Samsung Chromebook Plus V2</td>
            <td style="text-align:center;"> NAUTILUS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Google Pixel Slate</td>
            <td style="text-align:center;"> NOCTURNE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo Yoga Chromebook C630</td>
            <td style="text-align:center;"> PANTHEON</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip C433/C434</td>
            <td style="text-align:center;"> SHYVANA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebox CXI3</td>
            <td style="text-align:center;"> SION</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/fizz_wp.jpg" class="internal" title="fizz wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 14</td>
            <td style="text-align:center;"> SONA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook X2</td>
            <td style="text-align:center;"> SORAKA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 15 G1</td>
            <td style="text-align:center;"> SYNDRA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebox 3 (CN65)</td>
            <td style="text-align:center;"> TEEMO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/fizz_wp.jpg" class="internal" title="fizz wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td>Dell Inspiron Chromebook 14 (7460)</td>
            <td style="text-align:center;"> VAYNE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebox CBx1<br>Promethean Chromebox<br>SMART Chromebox G3<br>ViewSonic NMP660 Chromebox</td>
            <td style="text-align:center;"> WUKONG</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/fizz_wp.jpg" class="internal" title="fizz wp.jpg" target="_blank">screw</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Geminilake</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Asus Chromebook Flip C214/C234</td>
            <td style="text-align:center;"> AMPTON</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip C204</td>
            <td style="text-align:center;"> APEL</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 12b-ca0</td>
            <td style="text-align:center;"> BLOOG</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 14a-na0</td>
            <td style="text-align:center;"> BLOOGLET</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 14a-ca0/14b-ca0</td>
            <td style="text-align:center;"> BLOOGUARD</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 315 (CB315-3H, CB315-3HT)</td>
            <td style="text-align:center;"> BLORB</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Samsung Chromebook 4</td>
            <td style="text-align:center;"> BLUEBIRD</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 311<br>(CB311-9H, CB311-9HT, C733, C733U, C733T)</td>
            <td style="text-align:center;"> BOBBA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 311 (CP311-2H, CP311-2HN)<br>Acer Chromebook Spin 511 (R752T, R752TN)</td>
            <td style="text-align:center;"> BOBBA360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Samsung Chromebook 4+</td>
            <td style="text-align:center;"> CASTA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>NEC Chromebook Y2</td>
            <td style="text-align:center;"> DOOD</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 14 G6</td>
            <td style="text-align:center;"> DORP</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 314 (C933, C933T, CB314-1H, CB314-1HT)<br>Packard Bell Chromebook 314 (PCB314)</td>
            <td style="text-align:center;"> DROID</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 3100</td>
            <td style="text-align:center;"> FLEEX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebook VX11/VX11T<br>Poin2 Chromebook 11P</td>
            <td style="text-align:center;"> FOOB</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Poin2 Chromebook 11P</td>
            <td style="text-align:center;"> FOOB360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>ADVAN Chromebook 116<br>Axioo Chromebook<br>Baicells Chromebook BB01<br>CTL Chromebook NL71/CT/LTE<br>EVERCOSS Chromebook CB1<br>Edxis Chromebook 11 (S20-C)<br>JOI Chromebook C100<br>Multilaser Chromebook M11C-PC914<br>Pixart Rxart Chromebook<br>Poin2 Chromebook 11A<br>SPC Chromebook X1 Mini<br>Sector 5 E4 LTE Chromebook<br>WS Chromebook A101<br>Zyrex Chromebook M432</td>
            <td style="text-align:center;"> GARG</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Ascon Chromebook 11A<br>Axioo Chromebook 360<br>Baicells Chromebook BB01<br>CTL Chromebook NL71T/TW/TWB<br>EVERCOSS Chromebook CB1A<br>Edxis Chromebook 11 (S20-X)<br>JOI Chromebook C100<br>Multilaser Chromebook M11HC-PC915<br>Pixart Rxart Chromebook<br>Poin2 Chromebook 11A<br>SPC Chromebook X1 Mini<br>WS Chromebook A101<br>Zyrex Chromebook 360</td>
            <td style="text-align:center;"> GARG360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebook NL81/NL81T</td>
            <td style="text-align:center;"> GARFOUR</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 3100 2-in-1</td>
            <td style="text-align:center;"> GRABBITER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo Chromebook S340<br>Lenovo IdeaPad Flex 3 Chromebook 14"</td>
            <td style="text-align:center;"> LASER14</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo Ideapad 3 Chromebook</td>
            <td style="text-align:center;"> LICK</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 11 G2 EE</td>
            <td style="text-align:center;"> MEEP</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 11 G7 EE</td>
            <td style="text-align:center;"> MIMROCK</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook C424</td>
            <td style="text-align:center;"> NOSPIKE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 3400</td>
            <td style="text-align:center;"> ORBATRIX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 100e Chromebook Gen 2</td>
            <td style="text-align:center;"> PHASER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 300e Chromebook Gen 2<br>Lenovo 500e Chromebook Gen 2<br>Lenovo Chromebook C340<br>Lenovo IdeaPad Flex 3 Chromebook 11"<br>NEC Chromebook Y1</td>
            <td style="text-align:center;"> PHASER360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 512 (C851/C851T)</td>
            <td style="text-align:center;"> SPARKY</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 512 (R851TN)</td>
            <td style="text-align:center;"> SPARKY360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 11 G8 EE</td>
            <td style="text-align:center;"> VORTICON</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 11 G3 EE</td>
            <td style="text-align:center;"> VORTININJA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Whiskeylake</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Dell Latitude 5300 2-in-1 Chromebook Enterprise</td>
            <td style="text-align:center;"> ARCADA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">battery</a></td>
        </tr>
        <tr>
            <td>Dell Latitude 5400 Chromebook Enterprise</td>
            <td style="text-align:center;"> SARIEN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">battery</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Cometlake</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Lenovo Ideapad Flex 5 Chromebook</td>
            <td style="text-align:center;"> AKEMI</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>ASUS Meet Compute System (Intel 10th Gen)<br>CTL Meet Compute System (Intel 10th Gen)</td>
            <td style="text-align:center;"> AMBASSADOR</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper</td>
        </tr>
        <tr>
            <td>HP Chromebase 21.5"</td>
            <td style="text-align:center;"> DOOLY</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper</td>
        </tr>
        <tr>
            <td>HP Chromebook x360 14c-ca0</td>
            <td style="text-align:center;"> DRAGONAIR</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Dell Latitude 7410 Chromebook Enterprise</td>
            <td style="text-align:center;"> DRALLION</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Dell Latitude 7410 2-in-1 Chromebook Enterprise</td>
            <td style="text-align:center;"> DRALLION360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Pro c640 Chromebook</td>
            <td style="text-align:center;"> DRATINI</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebox 4</td>
            <td style="text-align:center;"> DUFFY</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Duffy_wp_jumper.png" class="internal" title="Duffy wp jumper.png" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Asus Fanless Chromebox</td>
            <td style="text-align:center;"> FAFFY</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper</td>
        </tr>
        <tr>
            <td>Meet Compute System - Series One (Intel 10th Gen)</td>
            <td style="text-align:center;"> GENESIS</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper</td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip C436FA</td>
            <td style="text-align:center;"> HELIOS</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Elite c1030 Chromebook<br>HP Chromebook x360 13c-ca0</td>
            <td style="text-align:center;"> JINLON</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebox CXI4</td>
            <td style="text-align:center;"> KAISA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Kaisa_wp.jpg" class="internal" title="Kaisa_wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 712 (C871)</td>
            <td style="text-align:center;"> KINDRED</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 713 (CP713-2W)</td>
            <td style="text-align:center;"> KLED</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Samsung Galaxy Chromebook</td>
            <td style="text-align:center;"> KOHAKU</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Samsung Galaxy Chromebook 2</td>
            <td style="text-align:center;"> NIGHTFURY</td>
            <td style="text-align:center;"> </td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebox G3</td>
            <td style="text-align:center;"> NOIBAT</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Noibat_wp.png" class="internal" title="Noibat wp.png" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>CTL Chromebox CBx2<br>Promethean Chromebox 2<br>ViewSonic NMP760 Chromebox</td>
            <td style="text-align:center;"> WYVERN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Wyvern_WP_jumper.png" class="internal" title="Wyvern WP jumper.png" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel TigerLake</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>FMV Chromebook 14F</td>
            <td style="text-align:center;"> CHRONICLER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip CX3</td>
            <td style="text-align:center;"> COLLIS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip CX5 (CX5400)</td>
            <td style="text-align:center;"> COPANO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip CX55, CX5 (CX5500), C536</td>
            <td style="text-align:center;"> DELBIN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook CX9 (CX9400)</td>
            <td style="text-align:center;"> DROBIT</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 14c-cc0</td>
            <td style="text-align:center;"> ELDRID</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Pro c640 G2 Chromebook<br>HP Chromebook 14b-nb0</td>
            <td style="text-align:center;"> ELEMI</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo IdeaPad Flex 5i Chromebook</td>
            <td style="text-align:center;"> LILLIPUP</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 5i-14 Chromebook<br>Lenovo Slim 5 Chromebook</td>
            <td style="text-align:center;"> LINDAR</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 514 (CP514-2H)</td>
            <td style="text-align:center;"> VOEMA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 515 (CB515-1W, CB515-1WT)</td>
            <td style="text-align:center;"> VOLET</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 514 (CB514-1W, CB514-1WT)</td>
            <td style="text-align:center;"> VOLTA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 713 (CP713-3W)</td>
            <td style="text-align:center;"> VOXEL</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel JasperLake</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>DINATECH Gen1 Chromebook  SG20JL1C<br>Multilaser Chromebook M11C-PC919<br>Allied Chromebook 11 N5100E</td>
            <td style="text-align:center;"> BEADRIX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Lenovo Flex 3i 15 / Ideapad Flex 3i Chromebook</td>
            <td style="text-align:center;"> BEETLEY</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Lenovo 3i-15 Chromebook</td>
            <td style="text-align:center;"> BLIPPER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/blipper_wp_jumper.jpg" class="internal" title="blipper_ wp_jumper.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Lenovo 100e Chromebook Gen 3</td>
            <td style="text-align:center;"> BOOKEM</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Boten_wp.jpg" class="internal" title="Boten wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Lenovo 500e Chromebook Gen 3</td>
            <td style="text-align:center;"> BOTEN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Boten_wp.jpg" class="internal" title="Boten wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Lenovo Flex 3i-11 / IdeaPad Flex 3i Chromebook</td>
            <td style="text-align:center;"> BOTENFLEX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Boten_wp.jpg" class="internal" title="Boten wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Samsung Galaxy Chromebook 2 360</td>
            <td style="text-align:center;"> BUGZZY</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Dell Chromebook 3110</td>
            <td style="text-align:center;"> CRET</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Cret_jumper.jpg" class="internal" title="Cret_jumper.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 3110 2-in-1</td>
            <td style="text-align:center;"> CRET360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>AOPEN Chromebox Mini 2</td>
            <td style="text-align:center;"> DEXI</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Acer Chromebox Mini CXM1</td>
            <td style="text-align:center;"> DITA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>HP Chromebook x360 11 G4 EE<br>HP Fortis x360 11 G3 J Chromebook</td>
            <td style="text-align:center;"> DRAWCIA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Drawcia_wp.jpg" class="internal" title="Drawcia wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 11 G9 EE</td>
            <td style="text-align:center;"> DRAWLAT</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Drawlat_wp.jpg" class="internal" title="Drawlat wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 14 G7</td>
            <td style="text-align:center;"> DRAWMAN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Drawman_wp.jpg" class="internal" title="Drawman wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>HP Fortis 14 G10 Chromebook</td>
            <td style="text-align:center;"> DRAWPER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Drawman_wp.jpg" class="internal" title="Drawman wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook CX1500CKA</td>
            <td style="text-align:center;"> GALITH</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Asus Chromebook CX1500FKA</td>
            <td style="text-align:center;"> GALITH360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Asus Chromebook CX1700CKA</td>
            <td style="text-align:center;"> GALLOP</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Asus Chromebook CX1 CX1102</td>
            <td style="text-align:center;"> GALNAT</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip CX1 CX1102</td>
            <td style="text-align:center;"> GALNAT360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Asus Chromebook CX1</td>
            <td style="text-align:center;"> GALTIC</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Asus Chromebook CX1400FKA</td>
            <td style="text-align:center;"> GALTIC360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>ADVAN Chromebook 116J<br>Centerm Chromebook M610<br>CTL Chromebook NL72<br>Edxis Chromebook 11<br>Poin2 Chromebook 11B<br>Zyrex Chromebook M432-64</td>
            <td style="text-align:center;"> KRACKO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>CTL Chromebook NL72T<br>LG Chromebook 11TC50Q/11TQ50Q<br>Poin2 Chromebook 11E</td>
            <td style="text-align:center;"> KRACKO360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>HP Chromebook x360 14a-ca1</td>
            <td style="text-align:center;"> LANDIA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>HP Chromebook 15a-na0</td>
            <td style="text-align:center;"> LANDRID</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Landrid_wp.jpg" class="internal" title="Landrid wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 14a-na1</td>
            <td style="text-align:center;"> LANTIS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Lantis_wp.jpg" class="internal" title="Lantis wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 14b-cb0</td>
            <td style="text-align:center;"> MADOO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 314 [CP314-1H, CP314-1HN]</td>
            <td style="text-align:center;"> MAGISTER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Maglia_wp.jpg" class="internal" title="Maglia wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 512 [C852]</td>
            <td style="text-align:center;"> MAGLET</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Maglia_wp.jpg" class="internal" title="Maglia wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 512 [R853TA/R853TNA]</td>
            <td style="text-align:center;"> MAGLIA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Maglia_wp.jpg" class="internal" title="Maglia wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 511 [C733/C734]</td>
            <td style="text-align:center;"> MAGLITH</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Maglith_wp.jpg" class="internal" title="Maglith wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 315 [CB315-4H/4HT]</td>
            <td style="text-align:center;"> MAGMA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/magma_wp_jumper.jpg" class="internal" title="magma_wp_jumper" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 314 [CB314-3H/3HT, C934/C934T]<br>Packard Bell Chromebook 314</td>
            <td style="text-align:center;"> MAGNETO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Maglia_wp.jpg" class="internal" title="Maglia wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 511 [R753T]</td>
            <td style="text-align:center;"> MAGOLOR</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Maglia_wp.jpg" class="internal" title="Maglia wp.jpg" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 317 [CB317-1H]</td>
            <td style="text-align:center;"> MAGPIE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Magpie_wp_jumper.png" class="internal" title="Magpie wp jumper.png" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>NEC Chromebook Y3</td>
            <td style="text-align:center;"> METAKNIGHT</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/Magpie_wp_jumper.png" class="internal" title="Magpie wp jumper.png" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Gateway Chromebook 15</td>
            <td style="text-align:center;"> PASARA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Acer Chromebook 311<br>ASUS Chromebook CA11J2<br>CTL Chromebook PX11EG<br>Edxis E-Lite Chromebook 11<br>Multilaser Chromebook M11C-PC924</td>
            <td style="text-align:center;"> PEEZER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Axioo Chromebook P11<br>CTL Chromebook PX11E<br>SPC Chromebook Z1 Mini<br>Zyrex Chromebook M432-2</td>
            <td style="text-align:center;"> PIRETTE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Axioo Chromebook P14<br>CTL Chromebook Enterprise<br>Gateway Chromebook 14</td>
            <td style="text-align:center;"> PIRIKA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Samsung Galaxy Chromebook Go</td>
            <td style="text-align:center;"> SASUKE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/sasuke_wp_jumper.jpg" class="internal" title="sasuke_wp_jumper" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>SamsungGalaxy Chromebook Go 11</td>
            <td style="text-align:center;"> SASUKETTE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td>Asus Chromebook CR1100CKA</td>
            <td style="text-align:center;"> STORO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/storo_wp_jumper.jpg" class="internal" title="storo_wp_jumper" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip CR1100FKA</td>
            <td style="text-align:center;"> STORO360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, <a href="/images/wp/storo_wp_jumper.jpg" class="internal" title="storo_wp_jumper" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>ASUS Fanless Chromebox CF40</td>
            <td style="text-align:center;"> TARANZA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper?</td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Alderlake</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>HP Elite c640 14 inch G3 Chromebook</td>
            <td style="text-align:center;"> ANAHERA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>AOpen Chromebox Commercial 3</td>
            <td style="text-align:center;"> AURASH</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Framework Laptop Chromebook Edition</td>
            <td style="text-align:center;"> BANSHEE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Dell Latitude 5430 Chromebook</td>
            <td style="text-align:center;"> CROTA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Dell Latitude 5430 2-in-1 Chromebook</td>
            <td style="text-align:center;"> CROTA360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Plus Spin 514 [CP514-4HN]</td>
            <td style="text-align:center;"> DOCHI</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip CX5 (CX5601)</td>
            <td style="text-align:center;"> FELWINTER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 14c-cd0</td>
            <td style="text-align:center;"> GIMBLE</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>HP Chromebox Enterprise G4</td>
            <td style="text-align:center;"> GLADIOS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 714 [CP714-1WN/2WN]</td>
            <td style="text-align:center;"> KANO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Lenovo ThinkCentre M60q Chromebox</td>
            <td style="text-align:center;"> KINOX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>ASUS Chromebox 5 (CN67)</td>
            <td style="text-align:center;"> KULDAX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebox CBx3</td>
            <td style="text-align:center;"> LISBON</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>ASUS Chromebook Plus CX34</td>
            <td style="text-align:center;"> MARASOV</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook CX34 Flip<br>Asus Chromebook Vibe CX34 Flip</td>
            <td style="text-align:center;"> MITHRAX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebox CXI5</td>
            <td style="text-align:center;"> MOLI</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Plus 515 (CB515-2H, CB515-2HT)</td>
            <td style="text-align:center;"> OMNIGUL</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Plus Enterprise 515 (CBE595-2/CBE595-2T)</td>
            <td style="text-align:center;"> ONMIKNIGHT</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 516 GE [CBG516-1H]<br>Acer Chromebook Plus 516 GE [CBG516-2H]</td>
            <td style="text-align:center;"> OSIRIS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo ThinkPad C14 Gen 1 Chromebook</td>
            <td style="text-align:center;"> PRIMUS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>HP Elite Dragonfly Chromebook</td>
            <td style="text-align:center;"> REDRIX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Lenovo Flex 5i Chromebook / IdeaPad Flex 5i Chromebook</td>
            <td style="text-align:center;"> TAEKO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Lenovo IdeaPad Gaming Chromebook 16</td>
            <td style="text-align:center;"> TANIKS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Lenovo 5i Chromebook 16"</td>
            <td style="text-align:center;"> TARLO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>HP Dragonfly Pro Chromebook</td>
            <td style="text-align:center;"> VELL</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Vero 514 (CBV514-1H, CBV514-1HT)</td>
            <td style="text-align:center;"> VOLMAR</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Vero 712 (CV872, CV872T)</td>
            <td style="text-align:center;"> ZAVALA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>Intel Alderlake-N</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>ASUS Chromebook CR12</td>
            <td style="text-align:center;"> ANRAGGAR</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>ASUS Chromebook CR12 Flip</td>
            <td style="text-align:center;"> ANRAGGAR360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 512 (R856T, R856LT)</td>
            <td style="text-align:center;"> CRAASK</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 311</td>
            <td style="text-align:center;"> CRAASKANA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 511 (R756T, R756LT)</td>
            <td style="text-align:center;"> CRAASKBOWL</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 315 (CB315-5H, CB315-5HT)</td>
            <td style="text-align:center;"> CRAASKINO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 511 (C736, C736T, C736L, C736LT)</td>
            <td style="text-align:center;"> CRAASKVIN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 314 (C936, C936T)<br>Acer Chromebook 314 (CB314-4H, CB314-4HT)<br>Acer Chromebook Plus 514 (CB514-4H, CB514-4HT)</td>
            <td style="text-align:center;"> CRAASNETO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 314 (CP314-2H, CP314-2HN)</td>
            <td style="text-align:center;"> CRAASWELL</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook Plus x360</td>
            <td style="text-align:center;"> JOXER</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Lenovo 500e Yoga Chromebook Gen 4<br>Lenovo Flex 3i Chromebook 12" / IdeaPad Flex 3i Chromebook 12"</td>
            <td style="text-align:center;"> PUJJO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Lenovo 100e Chromebook Gen 4</td>
            <td style="text-align:center;"> PUJJO1E</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Lenovo 14e Chromebook Gen 3</td>
            <td style="text-align:center;"> PUJJOTEEN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Lenovo Ideapad Slim 3i Chromebook</td>
            <td style="text-align:center;"> PUJJOTEEN15W</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebook NL73</td>
            <td style="text-align:center;"> QUANDISO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>CTL Chromebook NL73T</td>
            <td style="text-align:center;"> QUANDISO360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 3120</td>
            <td style="text-align:center;"> ULDREN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Dell Chromebook 3120 2-in-1</td>
            <td style="text-align:center;"> ULDREN360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook CR11 [CR1102C]</td>
            <td style="text-align:center;"> XIVU</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook CR11 [CR1102F]</td>
            <td style="text-align:center;"> XIVU360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook Plus 14a</td>
            <td style="text-align:center;"> YAHIKO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a>, <a href="/images/wp/yahiko_wp_jumper.jpg" class="internal" title="yahiko_wp" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>HP Fortis 14 inch G11 Chromebook</td>
            <td style="text-align:center;"> YAVIJO</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 15a-nb0</td>
            <td style="text-align:center;"> YAVIKS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>HP Fortis 11 inch G10 Chromebook</td>
            <td style="text-align:center;"> YAVILLA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td>HP Fortis x360 11 inch G5 Chromebook</td>
            <td style="text-align:center;"> YAVILLY</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>AMD Stoneyridge</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Acer Chromebook 315 (CB315-2H)</td>
            <td style="text-align:center;"> ALEENA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 11A G6 EE<br>HP Chromebook 11A G8 EE</td>
            <td style="text-align:center;"> BARLA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 14A G5</td>
            <td style="text-align:center;"> CAREENA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook 311 (C721)</td>
            <td style="text-align:center;"> KASUMI</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 311 (R721T)</td>
            <td style="text-align:center;"> KASUMI360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 14e Chromebook (S345)</td>
            <td style="text-align:center;"> LIARA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 100e Chromebook Gen 2 AMD</td>
            <td style="text-align:center;"> TREEYA</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 300e Chromebook Gen 2 AMD</td>
            <td style="text-align:center;"> TREEYA360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>AMD Picasso</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>HP Pro c645 Chromebook Enterprise<br>HP Chromebook 14b-na0</td>
            <td style="text-align:center;"> BERKNIP</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook 14a-nd0</td>
            <td style="text-align:center;"> DIRINBOZ</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 514 (CP514-1H, CP514-1W)</td>
            <td style="text-align:center;"> EZKINIL</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>HP Chromebook x360 14a-cb0</td>
            <td style="text-align:center;"> GUMBOZ</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip CM1</td>
            <td style="text-align:center;"> JELBOZ360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo ThinkPad C13 Yoga Chromebook</td>
            <td style="text-align:center;"> MORPHIUS</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 100e Chromebook Gen 3</td>
            <td style="text-align:center;"> VILBOZ</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 14e Chromebook Gen 2</td>
            <td style="text-align:center;"> VILBOZ14</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Lenovo 300e Chromebook Gen 3<br>NEC Chromebook Y1 Gen3A</td>
            <td style="text-align:center;"> VILBOZ360</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td>Asus Chromebook Flip CM5</td>
            <td style="text-align:center;"> WOOMAX</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ, battery)</a></td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>AMD Cezanne</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>Acer Chromebook Spin 514 [CP514-3H, CP514-3HH, CP514-3WH]</td>
            <td style="text-align:center;"> DEWATT</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper</td>
        </tr>
        <tr>
            <td>HP Elite c645 G2 Chromebook</td>
            <td style="text-align:center;"> NIPPERKIN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50 (SuzyQ)</a>, jumper</td>
        </tr>
        <tr>
            <td colspan="8"></td>
        </tr>
        <tr>
            <th colspan="8" style="text-align:left;"> <i>AMD Mendocino</i></th>
        </tr>
        <tr>
            <th scope="col"> Device Name</th>
            <th scope="col"> HWID/Board Name</th>
            <th scope="col"> RW_LEGACY <br> Firmware</th>
            <th scope="col"> UEFI Firmware <br>(Full ROM)</th>
            <th scope="col"> WP Method</th>
        </tr>
        <tr>
            <td>TBD</td>
            <td style="text-align:center;"> CRYSTALDRIFT</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a>, jumper</td>
        </tr>
        <tr>
            <td>Asus Chromebook CM34 Flip</td>
            <td style="text-align:center;"> FROSTFLOW</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a>, <a href="/images/wp/frostflow_wp_jumper.jpg" class="internal" title="frostflow_wp" target="_blank">jumper</a></td>
        </tr>
        <tr>
            <td>Acer Chromebook Plus 514 (CB514-3H, CB514-3HT)</td>
            <td style="text-align:center;"> MARKARTH</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a>, jumper</td>
        </tr>
        <tr>
            <td>Dell Latitude 3445 Chromebook</td>
            <td style="text-align:center;"> WHITERUN</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> ✅</td>
            <td style="text-align:center;"> <a href="https://docs.mrchromebox.tech/docs/firmware/wp/disabling.html" title="Firmware Write Protect">CR50/Ti50 (SuzyQ)</a>, jumper</td>
        </tr>
    </tbody>
</table>
</div>
</div></template>


